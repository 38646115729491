import { string2Status } from '@features/signals-status/shared/interface/astrion-signals-status.mapper';
import { createReducer, on } from '@ngrx/store';

import { SensorTrajectoriesActions } from './sensor-trajectories.actions';
import { SENSOR_TRAJECTORIES_INITIAL_STATE, SensorTrajectoriesState } from './sensor-trajectories.state';

export const reducer = createReducer(
  SENSOR_TRAJECTORIES_INITIAL_STATE,
  on(
    SensorTrajectoriesActions.trajectoriesFetched,
    (state: SensorTrajectoriesState, { sensorId, sensorTrajectories }): SensorTrajectoriesState => ({
      ...state,
      sensorId: sensorId,
      computationStatus: string2Status(sensorTrajectories.computationStatus),
      computationMessage: sensorTrajectories.computationMessage,
      nextComputationDate: sensorTrajectories.nextComputationDate,
      peakTrajectories: sensorTrajectories.peakTrajectories,
      harmonicTrajectories: sensorTrajectories.harmonicTrajectories,
    })
  ),
  on(
    SensorTrajectoriesActions.trajectoriesStatusChanged,
    (
      state: SensorTrajectoriesState,
      { sensorId, computationStatus, computationMessage, nextComputationDate }
    ): SensorTrajectoriesState => {
      if (sensorId != state.sensorId) {
        return state;
      } else {
        return {
          ...state,
          computationStatus,
          computationMessage,
          nextComputationDate,
        };
      }
    }
  ),
  on(
    SensorTrajectoriesActions.trajectoriesNotFound,
    SensorTrajectoriesActions.trajectoriesFetchFailed,
    (state: SensorTrajectoriesState, { sensorId }): SensorTrajectoriesState => ({
      ...state,
      ...SENSOR_TRAJECTORIES_INITIAL_STATE,
      sensorId,
    })
  )
);
