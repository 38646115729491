import { UNKNOWN_FLAGS } from '@features/data-validation/shared/interface/validation-flags';
import { AStrionSignalId } from '@features/signals/shared/interface/astrion-signal.interface';
import {
  AStrionSignalUploadResult,
  AStrionSignalUploadStatus,
} from '@features/signals/shared/interface/astrion-signal-upload-result.interface';
import { SignalsActions } from '@features/signals/shared/store/signals.actions';
import { createReducer, on } from '@ngrx/store';
import { LoadingState } from '@shared/interfaces/loading-state';
import { ComputationStatus } from '@shared/interfaces/processing-status';
import { recordFrom, recordMap } from '@tools/utilities/record-utilities';

import { SensorSignalsActions } from './sensor-signals.actions';
import { SENSOR_SIGNALS_INITIAL_STATE, SensorSignalsState } from './sensor-signals.state';

function isDefined<T>(value: T | null | undefined): value is T {
  return value !== null && value !== undefined;
}

export const reducer = createReducer(
  SENSOR_SIGNALS_INITIAL_STATE,
  on(
    SignalsActions.sensorSignalsFetchRequested,
    (state): SensorSignalsState => ({ ...state, loadingState: LoadingState.Loading, signals: [] })
  ),
  on(
    SignalsActions.sensorSignalsFetchFailed,
    (state): SensorSignalsState => ({ ...state, loadingState: LoadingState.Error })
  ),
  on(
    SignalsActions.sensorSignalsFetched,
    (state: SensorSignalsState, { sensorId, signals }): SensorSignalsState => ({
      ...state,
      loadingState: LoadingState.Loaded,
      sensorId,
      signals,
    })
  ),
  on(
    SignalsActions.signalDeleted,
    (state: SensorSignalsState, { signalId }): SensorSignalsState => ({
      ...state,
      signals: state.signals.filter(s => s.id != signalId),
    })
  ),
  on(
    SignalsActions.signalUpdated,
    (state: SensorSignalsState, { signal }): SensorSignalsState => ({
      ...state,
      signals: state.signals.map(s => (s.id === signal.id ? signal : s)),
    })
  ),
  on(
    SignalsActions.signalsUploaded,
    (state: SensorSignalsState, { uploads }: { uploads: AStrionSignalUploadResult[] }): SensorSignalsState => ({
      ...state,
      signals: [
        ...state.signals,
        ...uploads
          .filter(upload => upload.status === AStrionSignalUploadStatus.Success)
          .map(upload => upload.signal)
          .filter(isDefined)
          .filter(signal => signal.sensorId === state.sensorId)
          .filter(signal => !state.signals.some(cachedSignal => cachedSignal.id === signal.id))
          .map(signal => ({ ...signal })),
      ],
      statuses: {
        ...state.statuses,
        ...recordFrom(
          uploads
            .map(upload => upload.signal)
            .filter(isDefined)
            .map(s => [
              s.id as AStrionSignalId,
              { computationStatus: ComputationStatus.Scheduled, validationFlags: UNKNOWN_FLAGS },
            ])
        ),
      },
    })
  ),
  on(
    SignalsActions.signalsStatusFetched,
    (state: SensorSignalsState, { statuses }): SensorSignalsState => ({
      ...state,
      statuses: {
        ...state.statuses,
        ...recordMap(statuses, (id, currentState) => [
          id,
          {
            computationStatus:
              currentState.computationStatus || state.statuses[id as AStrionSignalId]?.computationStatus,
            computationMessage:
              currentState.computationMessage || state.statuses[id as AStrionSignalId]?.computationMessage,
            validationFlags: currentState.validationFlags || state.statuses[id as AStrionSignalId]?.validationFlags,
          },
        ]),
      },
    })
  ),
  on(
    SensorSignalsActions.validationFlagsFetched,
    (state: SensorSignalsState, action): SensorSignalsState => ({
      ...state,
      statuses: {
        ...state.statuses,
        [action.signalId]: {
          computationStatus: state.statuses[action.signalId]?.computationStatus,
          computationMessage: state.statuses[action.signalId]?.computationMessage,
          validationFlags: action.validationFlags,
        },
      },
    })
  )
);
