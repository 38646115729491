import { Guid } from '@app-types/guid.type';

export interface TrajectoryLabelDto {
  id: Guid;
  name: string;
}

export interface TrajectoryDtoBase {
  id: Guid;
  name: string;
  frequency: number;
  detectionPercentage: number;
  labels: TrajectoryLabelDto[];
  frequencyTrendMiniatureContentPath: string;
  energyTrendMiniatureContentPath: string;
}

export type PeakTrajectoryDto = TrajectoryDtoBase;

export interface HarmonicTrajectoryDto extends TrajectoryDtoBase {
  harmonicsCountTrendMiniatureContentPath: string;
  averageHarmonicEnergyTrendMiniatureContentPath: string;
  regularityTrendMiniatureContentPath: string;
  thdTrendMiniatureContentPath: string;
}

export interface SensorTrajectoriesDto {
  computationStatus: string;
  computationMessage: string | undefined;
  nextComputationDate: Date | null;
  peakTrajectories: PeakTrajectoryDto[] | null;
  harmonicTrajectories: HarmonicTrajectoryDto[] | null;
}

export enum TrajectoryType {
  Unknown = 0,
  PeakTrajectory = 1,
  HarmonicTrajectory = 2,
  None = 3,
}

export interface TrajectoryBase {
  id: Guid;
  type: TrajectoryType;
  name: string;
  frequency: number;
  detectionPercentage: number;
  isLabeled: boolean;
  frequencyTrendMiniatureContentPath: string;
  energyTrendMiniatureContentPath: string;
}

export interface PeakTrajectory extends TrajectoryBase {
  type: TrajectoryType.PeakTrajectory;
}

export interface HarmonicTrajectory extends TrajectoryBase {
  type: TrajectoryType.HarmonicTrajectory;
  harmonicsCountTrendMiniatureContentPath: string;
  averageHarmonicEnergyTrendMiniatureContentPath: string;
  regularityTrendMiniatureContentPath: string;
  thdTrendMiniatureContentPath: string;
}

export type Trajectory = PeakTrajectory | HarmonicTrajectory;

export interface TrajectoriesList {
  peakTrajectories: PeakTrajectory[] | null;
  harmonicTrajectories: HarmonicTrajectory[] | null;
}

export interface SensorTrajectories extends TrajectoriesList {
  computationStatus: string;
  computationMessage: string | undefined;
  nextComputationDate: Date | null;
}

const trajectoryTypeNameMap = new Map<TrajectoryType, string>([
  [TrajectoryType.PeakTrajectory, 'peak-trajectory'],
  [TrajectoryType.HarmonicTrajectory, 'harmonic-trajectory'],
]);

export const trajectoryTypeName = (type: TrajectoryType): string => trajectoryTypeNameMap.get(type)!;

export function isPeakTrajectory(trajectory: Trajectory): trajectory is PeakTrajectory {
  return trajectory.type === TrajectoryType.PeakTrajectory;
}

export function isHarmonicTrajectory(trajectory: Trajectory): trajectory is HarmonicTrajectory {
  return trajectory.type === TrajectoryType.HarmonicTrajectory;
}
