import { ItemDescription } from './item-description';

export const enum ComputationStatus {
  Unknown = 0,
  Dirty = 1,
  Scheduled = 2,
  InProgress = 3,
  Failed = 4,
  NotComputed = 5,
  Succeeded = 6,
}

export function parseComputingStatus(
  status: ComputationStatus | undefined,
  computationMessage?: string | undefined
): ItemDescription {
  switch (status) {
    case ComputationStatus.Succeeded:
      return {
        icon: 'check_circle',
        color: '#749e31',
        tooltip: 'Computation complete',
      };
    case ComputationStatus.Scheduled:
    case ComputationStatus.Dirty:
      return {
        icon: 'schedule',
        color: '#165FD5',
        tooltip: 'Recomputation pending',
      };
    case ComputationStatus.InProgress:
      return {
        icon: 'progress_activity',
        itemClass: 'animate-spin',
        color: '#4b6884',
        tooltip: 'Computation in progress',
      };
    case ComputationStatus.Failed:
      return {
        icon: 'running_with_errors',
        color: '#E63109',
        tooltip: computationMessage || 'Computation not complete',
      };
    case ComputationStatus.NotComputed:
      return {
        icon: 'cancel',
        color: '#c1bde3',
        tooltip: computationMessage || 'Computation not computed',
      };
    case ComputationStatus.Unknown:
      return {
        icon: 'question_mark',
        color: '#c1bde3',
        tooltip: computationMessage || 'Status is unknown',
      };
    default:
      // Not loaded yet
      return {
        icon: 'pending',
        color: '#e0e0e0',
        tooltip: '',
      };
  }
}
